import React, { useState } from 'react';
import './index.scss';
import PlaceComponent from '../Place/PlaceComponent';
import { pathOr } from 'ramda';
import { useTranslation } from 'react-i18next';
import { Pagination } from 'antd';
import i18next from 'i18next';

const TicketComponent = props => {
  const ticket = props.tickets[0];
  const isMobile = window.screen.width <= 990;
  const collapsedTicketItemsNumber = isMobile ? 2 : 4;
  const defaultPageSize = 20;
  const [displayedIndexes, setDisplayedIndexes] = useState({
    from: 0,
    to: collapsedTicketItemsNumber - 1,
  });
  const event = pathOr({}, ['event'], ticket);
  const startTime = new Date(pathOr('', ['startTime'], event));

  const dateFormation = () => {
    const utcDate = new Date(
      Date.UTC(
        startTime.getUTCFullYear(),
        startTime.getUTCMonth(),
        startTime.getUTCDate(),
        startTime.getUTCHours(),
        startTime.getUTCMinutes(),
        startTime.getUTCSeconds(),
      ),
    );

    const hoursUTC =
      utcDate.getUTCHours() +
      Number(window.parametrize('REACT_APP_HOURS_FROM_UTC'));

    utcDate.setUTCHours(hoursUTC);

    const date = new Date(
      utcDate.getUTCFullYear(),
      utcDate.getUTCMonth(),
      utcDate.getUTCDate(),
      utcDate.getUTCHours(),
      utcDate.getUTCMinutes(),
      utcDate.getUTCSeconds(),
    );

    return date;
  };

  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();
  const onPageChange = (page, pageSize) => {
    const countedIdexes = {
      from: (page - 1) * pageSize,
      to: page * pageSize - 1,
    };
    setDisplayedIndexes(countedIdexes);
  };

  return (
    <div
      className="ticket"
      style={{
        backgroundColor: '#ffffff',
      }}
    >
      <div className="ticket__season-container">
        <div
          className="ticket__season-container__icon"
          style={{
            backgroundImage:
              'url(' +
              pathOr('', ['stage', 'tournament', 'logo', 'publicLink'], event) +
              ')',
          }}
        />
        <div className="ticket__season-container__title">
          {pathOr('', ['stage', 'tournament', 'title'], event)}
        </div>
      </div>

      <div className="ticket__title-container">
        {/*<div*/}
        {/*  className="ticket__title-container__first"*/}
        {/*  style={{*/}
        {/*    color: props.colors.primary,*/}
        {/*  }}*/}
        {/*>*/}
        {/*  {pathOr('', ['title'], event)}*/}
        {/*</div>*/}
        {/*<div className="ticket__title-container__vs desktop">*/}
        {/*  &nbsp;vs&nbsp;*/}
        {/*</div>*/}
        <div className="ticket__title-container__second">
          {/*<div className="ticket__title-container__vs mobile">vs&nbsp;</div>*/}
          {pathOr('', ['title'], event)}
        </div>
      </div>

      <div className="ticket__info-container">
        <div className="ticket__info-container__icon" />
        <div
          className="ticket__info-container__day"
          style={{
            color: props.colors.primary,
          }}
        >
          {new Intl.DateTimeFormat(i18next.language, { weekday: 'short' })
            .format(dateFormation())
            .toUpperCase()}
          &nbsp;
        </div>
        <div className="ticket__info-container__date">
          {new Intl.DateTimeFormat(i18next.language, {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          })
            .format(dateFormation())
            .toUpperCase()}
        </div>
      </div>

      <div className="ticket__tickets-container">
        <>
          {props.tickets.map((ticket, index) => {
            if (
              index >= displayedIndexes.from &&
              index <= displayedIndexes.to
            ) {
              return (
                // <div>Место</div>
                <PlaceComponent
                  ticket={ticket}
                  key={ticket.id}
                  userPhone={props.userPhone}
                  client={props.client}
                  isTicket={true}
                  colors={props.colors}
                  walletPassAvailable={
                    window.parametrize('REACT_APP_ALLOW_WALLETPASS') === 'true'
                  }
                />
              );
            } else {
              return null;
            }
          })}
          {isOpen && props.tickets.length > defaultPageSize ? (
            <Pagination
              pageSize={defaultPageSize}
              onChange={onPageChange}
              showSizeChanger={false}
              className="ticket__tickets-container__pagination"
              total={props.tickets.length}
              colorPrimary="red"
            />
          ) : null}
        </>
      </div>

      {/*<div className="ticket__logo-container__bg" />*/}
      {/*<div*/}
      {/*  className="ticket__logo-container"*/}
      {/*  style={{*/}
      {/*    backgroundImage:*/}
      {/*      'url(' + pathOr('', ['team2', 'logo', 'publicLink'], event) + ')',*/}
      {/*  }}*/}
      {/*/>*/}
      {!isOpen && props.tickets.length > (isMobile ? 2 : 4) ? (
        <div
          className="ticket__long-button"
          onClick={() => {
            setOpen(true);
            setDisplayedIndexes({ from: 0, to: defaultPageSize - 1 });
          }}
        >
          <div className="ticket__long-button__icon"></div>
          <div className="ticket__long-button__text">
            {t('inventory.season_ticket_component.ticket__long-button__text')}
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default TicketComponent;
