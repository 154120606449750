import React, { useEffect, useState } from 'react';
import './index.scss';
import { GET_TICKETS, GET_SEASON_TICKETS } from '../../graphql';
import SeasonTicketComponent from './SeasonTicket/SeasonTicketComponent';
import TicketComponent from './Ticket/TicketComponent';
import PlaceholderComponent from './Placeholder/PlaceholderComponent';
import { pathOr } from 'ramda';
import { useTranslation } from 'react-i18next';

const InventoryComponent = props => {
  const [ticketsArr, setTickets] = useState([]);
  const [seasonTicketsArr, setSeasonTickets] = useState([]);
  const [isLoaded, setLoaded] = useState(false);
  const [showTickets, setShowTickets] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    const init = async () => {
      const ticketRes = await props.client.query({
        query: GET_TICKETS,
        fetchPolicy: 'no-cache',
      });

      const ticketList = pathOr(
        [],
        ['data', 'ticket', 'getOwnList', 'list'],
        ticketRes,
      ).map(item => {
        return {
          ...item,
          eventId: pathOr('', ['event', 'id'], item),
        };
      });

      const filteredEventList = ticketList.filter((obj, pos, arr) => {
        return (
          arr.map(mapObj => mapObj['eventId']).indexOf(obj['eventId']) === pos
        );
      });

      const filteredTicketList = filteredEventList.map(item => {
        return ticketList.filter(ticket => ticket.eventId === item.eventId);
      });

      const seasonTicketRes = await props.client.query({
        query: GET_SEASON_TICKETS,
        fetchPolicy: 'no-cache',
      });

      const seasonTicketsArr = pathOr(
        [],
        ['data', 'seasonTicket', 'getOwnList', 'list'],
        seasonTicketRes,
      ).map(item => {
        return {
          ...item,
          descriptorId: pathOr('', ['descriptor', 'id'], item),
        };
      });

      const filteredDescriptorList = seasonTicketsArr.filter(
        (obj, pos, arr) => {
          return (
            arr
              .map(mapObj => mapObj['descriptorId'])
              .indexOf(obj['descriptorId']) === pos
          );
        },
      );

      const filteredSeasonTicketsArr = filteredDescriptorList.map(item => {
        return seasonTicketsArr.filter(
          ticket => ticket.descriptor.id === item.descriptor.id,
        );
      });

      setTickets(filteredTicketList);

      setSeasonTickets(filteredSeasonTicketsArr);

      setLoaded(true);
    };
    if (!isLoaded) {
      init();
    }
  }, []);
  return (
    <div className="inventory">
      <div className="inventory__top-section">
        <div
          className="inventory__top-section__text"
          style={{
            color: props.colors.navigation_1,
          }}
        >
          {t('inventory.inventory__top-section__text')}
        </div>
        {/*<div className="inventory__top-section__button-container">*/}
        {/*  <div*/}
        {/*    className={'inventory__top-section__button-container__button'}*/}
        {/*    onClick={() => {*/}
        {/*      setShowTickets(true);*/}
        {/*    }}*/}
        {/*    style={{*/}
        {/*      color: showTickets*/}
        {/*        ? props.colors.navigation_2*/}
        {/*        : props.colors.navigation_1,*/}
        {/*      backgroundColor: showTickets*/}
        {/*        ? props.colors.primary*/}
        {/*        : props.colors.navigation_2,*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    {t(*/}
        {/*      'inventory.inventory__top_section__button_container__button.tickets',*/}
        {/*    )}*/}
        {/*  </div>*/}
        {/*  <div*/}
        {/*    className={*/}
        {/*      !showTickets*/}
        {/*        ? 'inventory__top-section__button-container__button__active'*/}
        {/*        : 'inventory__top-section__button-container__button'*/}
        {/*    }*/}
        {/*    onClick={() => {*/}
        {/*      setShowTickets(false);*/}
        {/*    }}*/}
        {/*    style={{*/}
        {/*      color: !showTickets*/}
        {/*        ? props.colors.navigation_2*/}
        {/*        : props.colors.navigation_1,*/}
        {/*      backgroundColor: !showTickets*/}
        {/*        ? props.colors.primary*/}
        {/*        : props.colors.navigation_2,*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    {t(*/}
        {/*      'inventory.inventory__top_section__button_container__button.subscriptions',*/}
        {/*    )}*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>

      {isLoaded ? (
        <div>
          {showTickets ? (
            ticketsArr.length ? (
              ticketsArr.map(tickets => {
                return (
                  <TicketComponent
                    tickets={tickets}
                    client={props.client}
                    key={tickets[0].eventId}
                    userPhone={props.userPhone}
                    colors={props.colors}
                  />
                );
              })
            ) : (
              <PlaceholderComponent
                styles={{
                  fanThemeClubNavigation1: props.colors.navigation_1,
                  fanThemeClubPrimaryLight: props.colors.primaryLight,
                }}
              />
            )
          ) : seasonTicketsArr.length ? (
            seasonTicketsArr.map(tickets => {
              return (
                <SeasonTicketComponent
                  tickets={tickets}
                  client={props.client}
                  key={tickets[0].descriptorId}
                  userPhone={props.userPhone}
                  colors={props.colors}
                  styles={{
                    fanThemeClubNavigation1: props.colors.navigation_1,
                    fanThemeClubPrimaryLight: props.colors.primaryLight,
                  }}
                />
              );
            })
          ) : (
            <PlaceholderComponent colors={props.colors} />
          )}
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default InventoryComponent;
